import React from "react"
import Im from "immutable"
import { Tooltip } from "react-tippy";


const getMetricUrl = (projectId, metricId) =>
    `/api/dashboard/project/${projectId}/children/metric/${metricId}/values`;


export const getIndicatorUrl = (projectId, indicatorId) =>
    `/api/dashboard/project/${projectId}/children/indicator/${indicatorId}/rags`;


const tenantConfigs = {
    "carbon": {
        turnover: {
            id: "069cc076-2658-4dab-ac2c-a32681bd5dcc",
            title: "Apportioned turnover",
            getUrl: getMetricUrl,
        },
        scopeOne: {
            id: "aa19dbbb-84d3-473c-aa08-4d6650bb09ee",
            title: "Scope 1 emissions (tCO2e) apportioned",
            getUrl: getIndicatorUrl,
        },
        scopeTwo: {
            id: "b00e9d0e-660f-4a1c-b6fa-30aa52c8e3d4",
            title: "Scope 2 emissions (tCO2e) apportioned - location based",
            getUrl: getIndicatorUrl,
        },
        scopeThree: {
            id: "91a547c4-4ab4-49dd-83fa-56ea1f036b38",
            title: "Scope 3 emissions (tCO2e) apportioned",
            getUrl: getIndicatorUrl,
        },
        turnoverPerMilId: {
            id: "e92b6828-4b5d-4c22-a89a-9abbab7e0434",
            title: "Total emissions (tCO2e) / £1m turnover apportioned",
            getUrl: getIndicatorUrl,
        },
    },
    "cif": {
        turnover: {
            id: "cdfbf225-cb68-4fea-8324-25bfb5aee41a",
            title: "Apportioned turnover",
            getUrl: getMetricUrl,
        },
        scopeOne: {
            id: "b6c80c2f-5ecc-4285-ac9e-c14ad2d895c9",
            title: "Scope 1 emissions (tCO2e) apportioned",
            getUrl: getIndicatorUrl,
        },
        scopeTwo: {
            id: "faef273d-4c53-4d2f-a6f6-cc3999b5007f",
            title: "Scope 2 emissions (tCO2e) apportioned - location based",
            getUrl: getIndicatorUrl,
        },
        scopeThree: {
            id: "52a465c4-a15b-45f2-9126-1641b4f43790",
            title: "Scope 3 emissions (tCO2e) apportioned",
            getUrl: getIndicatorUrl,
        },
        turnoverPerMilId: {
            id: "44886f10-8c05-4445-9e75-847b6bd1a30d",
            title: "Total emissions (tCO2e) / €1m turnover apportioned",
            getUrl: getIndicatorUrl,
        },
    }
}

function TooltipTitle({ children, tooltipText }) {
    return (
        <Tooltip animateFill={false} html={<div className="text-left">{tooltipText}</div>}>
            {children}
        </Tooltip>
    );
}


export const getUnits = () => {
    const DEFAULT_UNITS = "£" // "£", "€"
    return location.hostname.includes("cif") ? "€" : DEFAULT_UNITS
}


export function ColumnName({fetches, fetchKey, showCumulative, year, showUnits}) {
    let title = fetches.getIn([fetchKey, "title"])
    if (showCumulative) {
        title += ` in ${year || 'All Time'}`
    }
    if (showUnits) {
        title += ` (${getUnits()})`
    }   
    return (
        <TooltipTitle tooltipText={<>{title}</>}>
            {title}
        </TooltipTitle>
    )
}



export const getTenantConfig = () => {
    const DEFAULT_TENANT = "carbon"
    // Search through the configs to get the one to use based on hostname
    for (let [key, tenantConfig] of Object.entries(tenantConfigs)) {
        if (location.hostname.includes(key)) {
            console.log("getTenantConfig using default host key", key)
            return Im.fromJS(tenantConfig)
        }
    }
    // Return the default carbon config (wont work on any other tenancy though)
    console.log("getTenantConfig using default host", DEFAULT_TENANT)
    return Im.fromJS(tenantConfigs[DEFAULT_TENANT])
};