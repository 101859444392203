import React from "react";
import Im from "immutable";
import DataTable from "react-data-table-component";
import Octicon from "react-octicon";
import useApi, { useApis, usePostCache } from "storybook-dashboard/utils/fetching";
import { roundDecimals } from "storybook-dashboard/dashboard/charts/utils";
import { YearSelector, useYearSelector } from "storybook-dashboard/components/yearRangePicker";
import { generateAndDownloadCSV } from "storybook-dashboard/dashboard/carbonComponents/supplierDataValuesCsv";
import { getTenantConfig, getIndicatorUrl, ColumnName, getUnits } from "./utils";

const mapSupplierToValue = (indicatorData, key = "indicator_result") => {
  console.log(indicatorData?.toJS(), "csvData");
  return indicatorData?.reduce((acc, cur) => {
    return acc?.set(cur?.get("name"), cur?.get(key));
  }, Im.Map());
};

// Get the data for the tenant we are on
const requiredData = getTenantConfig();

const formatValue = (value, currency = false) => {
  return value ? `${currency ? getUnits() : ""}${roundDecimals(value)}` : "";
};

const isNumber = (v) => !isNaN(parseFloat(v));

const sumEmissions = (item) => {
  let scopes = [item.scopeOne, item.scopeTwo, item.scopeThree];
  return scopes.reduce((a, c) => (isNumber(c) ? a + c : a), null);
};

export const SupplierBreakdownTable = ({ projectId }) => {
  const yearSelectorProps = useYearSelector("All time");
  const { dateRange } = yearSelectorProps;
  console.log({ dateRange });

  const year = dateRange.get("from_date") && dateRange.get("to_date") && Number(dateRange.get("from_date").slice(0, 4));
  const fetchFilters = {
    filters: {
      commit__reporting_period__endDate__gt: dateRange.get("from_date") || "2018-01-01T00:00:00", //"2022-01-01T00:00:00",
      commit__reporting_period__endDate__lte:
        dateRange.get("to_date") || `${new Date().getFullYear() + 1}-01-01T00:00:00`, //"2023-01-01T00:00:00",
    },
  };

  let _fetches = requiredData
    ?.map((value) => {
      let getUrlFunction = value.get("getUrl");
      let _id = value.get("id");
      return usePostCache(getUrlFunction(projectId, _id), fetchFilters);
    })
    ?.toJS();

  let { fetches, isLoading } = useApis(_fetches);

  // Initial reshaping of the data
  let data = requiredData?.map((value, key) => {
    let isIndicator = value.get("getUrl") == getIndicatorUrl;
    let _data = fetches[key].data;
    let imData = Im.fromJS(isIndicator ? _data?.refs : _data);
    return mapSupplierToValue(imData, isIndicator ? "indicator_result" : "value");
  });

  let dataByClient = data.reduce((acc, clientsData, key) => {
    clientsData?.map((value, name) => {
      let data = acc.get(name) || Im.Map({ name });
      acc = acc.set(name, data.set(key, value));
    });
    return acc;
  }, Im.Map());

  let rowData = [...dataByClient.toList()?.map((client) => Object.fromEntries(client.entries()))];

  const columns = [
    {
      name: "Supplier name",
      selector: (item) => item.name,
      sortable: true,
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="turnoverPerMilId" />,
      // sortable: true,
      selector: (item) => item.turnoverPerMilId,
      format: (item) => formatValue(item.turnoverPerMilId),
      grow: 1,
      style: { paddingLeft: "5px" },
      sortable: true,
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="scopeOne" />,
      selector: (item) => item.scopeOne,
      format: (item) => formatValue(item.scopeOne),
      sortable: true,
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="scopeTwo" />,
      selector: (item) => item.scopeTwo,
      format: (item) => formatValue(item.scopeTwo),
      sortable: true,
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="scopeThree" />,
      selector: (item) => item.scopeThree,
      format: (item) => formatValue(item.scopeThree),
      sortable: true,
    },
    {
      name: (
        <ColumnName fetches={requiredData} fetchKey="turnover" showCumulative={true} year={year} showUnits={true} />
      ),
      selector: (item) => item.turnover,
      format: (item) => formatValue(item.turnover, true),
      sortable: true,
    },
    {
      name: "Total emissions",
      selector: (item) => sumEmissions(item),
      format: (item) => formatValue(sumEmissions(item)),
      sortable: true,
    },
  ];

  return (
    <div className="shadow col-sm-12 bg-white rounded my-3">
      <div className="d-flex flex-row justify-content-end p-2">
        <YearSelector {...yearSelectorProps} includeCustom={false} includeNone={true} />
        <button className="btn btn-link ml-2" onClick={() => generateAndDownloadCSV(data, year)}>
          <Octicon name="cloud-download" /> Export to csv
        </button>
      </div>
      <DataTable columns={columns} data={rowData} fixedHeader={true} />
    </div>
  );
};

export default SupplierBreakdownTable;
